// 机票规则
import wrap_cache from 'nllib/src/promise-helper/wrap_cache/1.0.1';
import Q from 'q'
import flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";

const consumer_air_ticket_flightRules = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }
    const clear_cache = pParameter.clear_cache || false;
    const p = pParameter;
    const cache_key = `test-${JSON.stringify(p)}`;
    const fn = flightRules;
    const get_parameter_for_fn = function () {
        return Q.resolve(p);
    };
    return wrap_cache({
        clear_cache,
        cache_key,
        fn,
        get_parameter_for_fn,
    });
}

export default consumer_air_ticket_flightRules