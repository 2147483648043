//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ChangClause",
  data() {
    return {
      loading: true
    };
  },
  methods: {},
  computed: {
    returnRuleText() {
      let ruleArr = this.ruleText
      if (!ruleArr || ruleArr.length === 0) {
        return ruleArr
      } else if (!ruleArr[0] || !ruleArr[1]) {
        return ruleArr
      } else {
        let titItemArr = []
        let titArr = []
        ruleArr.forEach(value => {
          value.forEach(value1 => {
            if (titArr.indexOf(value1.ruleTime) === -1) {
              titArr.push(value1.ruleTime)
              titItemArr.push(value1)
            }
          })
        })
        titItemArr = titItemArr.sort(((a, b) => {
          return a.serial - b.serial
        }))
        titArr = []
        titItemArr.forEach(value => {
          titArr.push(value.ruleTime)
        })
        let resultRule = []
        ruleArr.forEach((value, index) => {
          let params = []
          let ruleParams = []
          value.forEach(value2 => {
            ruleParams.push(value2.ruleTime)
          })
          titArr.forEach((value1, index1) => {
            let ruleIndex = ruleParams.indexOf(value1)
            if (ruleIndex >= 0) {
              params.push(value[ruleIndex])
            } else {
              const item = {
                rate: '',
                rateText: '',
                ruleTime: value1,
              }
              params.push(item)
            }
          })
          resultRule.push(params)
        })
        return resultRule
      }
      return ruleArr
    },
  },
  props: ["ruleText", "luggage"],
  created() {}
};
